import { loadStripe } from '@stripe/stripe-js'
import Pdf from 'assets/doc-examples/interview_sample.pdf'
import axios from 'axios'
import { Layout, SEO } from 'components/common'
import { Header } from 'components/theme'
import React, { useState } from 'react'
import { Oval } from 'react-loader-spinner'
import styled from 'styled-components'

const InputSubmit = styled.input.attrs({ type: 'file' })`
  background: white;
  color: black;
  cursor: pointer;
  margin-bottom: 0;
  border-radius: 5px;
  height: 40px;
  border-color: transparent;
  box-shadow: 0px;
  outline: none;
  transition: 0.15s;
  text-align: center;
  ::file-selector-button {
    cursor: pointer;
    font-weight: bold;
    color: white;
    padding: 0.5em;
    border: thin solid grey;
    border-radius: 3px;
    height: 40px;
    background-color: #3498db;
    &:active {
      background-color: #f1ac15;
    }
    &:hover {
      background-color: #f1ac15;
    }
  }
`

const Button = styled.button`
  margin-top: 1em;
  width: 8em;
  background: #2f2f2f;
  color: white;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background: #f1ac15;
  }
`

const RootContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 280px) {
    width: 100%;
    margin-left: 2em;
    margin-right: 2em;
  }
`

const SpeakersContainer = styled.div`
  margin-top: 1em;
  text-align: center;
`

const FormContainer = styled.div`
  margin-bottom: 5em;
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    margin-left: 1em;
    margin-right: 1em;
  }
`

const ProgressBar = styled.progress`
  height: 2em;
  width: 16em;

  @media (max-width: 280px) {
    width: 100%;
  }
`

const Instructions = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;

  @media (max-width: 410px) {
    width: 100%;
  }
`

const Upload = () => {
  const [selectedFile, setSelectedFile] = useState()
  const [numberOfSpeakers, setNumberOfSpeakers] = useState(1)
  const [progress, setProgress] = useState(0)
  const [progressBar, setProgressBar] = useState(false)
  const [error, setError] = useState(false)

  const changeHandler = (e) => {
    setSelectedFile(e.target.files[0])
  }

  const handleFileUpload = () => {
    const axiosInstance = axios.create({
      baseURL: process.env.GATSBY_ROOT_URL,
    })

    const formData = new FormData()
    setError(false)

    if (!selectedFile) {
      setProgressBar(false)
      return setError('Please select a file to upload')
    }

    if (numberOfSpeakers > 10 || numberOfSpeakers <= 0) {
      return setError('Please select a number in a range 1 to 10')
    }

    formData.append('file', selectedFile)
    formData.append('number_of_speakers', numberOfSpeakers)

    axiosInstance
      .post('/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100,
          )
          setProgressBar(true)
          setProgress(progress)
        },
      })
      .then(async (response) => {
        const stripe = await loadStripe(
          process.env.GATSBY_STRIPE_PUBLISHABLE_KEY,
        )
        stripe
          .redirectToCheckout({ sessionId: response.data.message })
          .catch((error) => {
            setProgress(0)
            setError(error)
          })
      })
      .catch((error) => {
        setProgress(0)
        setProgressBar(false)
        setError(error.response.data.message)
      })
  }

  return (
    <Layout>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <SEO title='Upload' />
      <Header fullHeader={false} showNavbarLinks={false} />
      <RootContainer>
        <FormContainer>
          <Instructions>
            <h3>
              Upload a file and get a transcript in Word format with user labels
              and timestamps!{' '}
              <a
                style={{
                  color: 'inherit',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                href={Pdf}
                target='_blank'
                rel='noreferrer'
              >
                See example file.
              </a>
            </h3>
            <h3>Instructions</h3>
            <ul style={{ listStyle: 'none' }}>
              <li>
                <span style={{ fontWeight: '700' }}>1.</span> Upload any
                <span style={{ fontWeight: '700' }}> mp3</span>,
                <span style={{ fontWeight: '700' }}> mp4</span>, or
                <span style={{ fontWeight: '700' }}> wav</span> file - minimum
                length is 10 minutes and maximum length is 4 hours.
              </li>
              <li>
                <span style={{ fontWeight: '700' }}>2.</span> Select the number
                of speakers in your recording.
              </li>
              <li>
                <span style={{ fontWeight: '700' }}>3.</span> Complete checkout
                session
              </li>
              <li>
                <span style={{ fontWeight: '700' }}>4.</span> Once your file is
                processed, you will get a secure download link for your
                transcript from support@expresstranscriber.com sent to the email
                you used during checkout
              </li>
              <li>
                <span style={{ fontWeight: '700' }}>5.</span> If your recording
                has multiple speakers, they will be labeled as spk_0, spk_1,
                etc. You can easily rename them by selecting them and just doing
                find and replace (<code>ctrl + f</code>on Windows;{' '}
                <code>cmd + f</code> on Mac) either in Microsoft Word or Google
                Docs.{' '}
              </li>
            </ul>
          </Instructions>
          <InputSubmit
            formEncType='multipart/formdata'
            name='file'
            onChange={changeHandler}
          />
          <SpeakersContainer>
            <label>
              Number of speakers in this audio/video file (max number of
              speakers is 10):
              <input
                type='number'
                value={numberOfSpeakers}
                onChange={(e) => setNumberOfSpeakers(e.target.value)}
                min='1'
                max='10'
                style={{ marginLeft: '0.5em', alignSelf: 'center' }}
              />
            </label>
          </SpeakersContainer>
          <Button type='button' onClick={handleFileUpload}>
            Submit
          </Button>
          {progressBar ? (
            <div
              style={{
                marginTop: '1em',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ProgressBar value={progress} max={100}></ProgressBar>
              {' ' + progress}%
            </div>
          ) : null}
          <div>
            {!error && progress === 100 ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '1em',
                }}
              >
                <p style={{ textAlign: 'center', fontWeight: '700' }}>
                  Upload successful! You are being redirected to checkout.
                </p>
                <Oval
                  height={60}
                  width={60}
                  color='#00308F'
                  secondaryColor='#7CB9E8'
                />
              </div>
            ) : (
              <p
                style={{
                  color: 'red',
                  textAlign: 'center',
                  marginTop: '1em',
                }}
              >
                {error}
              </p>
            )}
          </div>
        </FormContainer>
      </RootContainer>
    </Layout>
  )
}

export default Upload
